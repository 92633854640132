
export const bannerType = {
    // 新闻中心
    newscenter: 7,
    //企业文化四张图
    companyCulture4: 8,
    //企业文化八张图
    companyCulture8: 9,
    // 走进惠发
    inHuiFa: 10,
    //投资者服务联络站广告位
    investor: 11,
    //产品展示顶部大图
    productDisplay: 12,
    //项目布局顶部大图
    projectLayout: 13,
    //人力资源顶部大图
    humanResources: 14,
    //投资者关系顶部大图
    investorConcern: 15,
    //联系我们顶部大图
    contactUs: 16,
   
   };
