<template>
	<div class="investment">
		<div class="investment-image">
			<img :src="img_list[0].img" alt="" style="width: 100%;">
		</div>
        <div class="header-investment">
			<div class="investmentHeader ">
				<div class="sort row-center">
					<!-- <div :class="(activeNum == item.id ? 'active':'') + ' sort-item row-center'" @click="active(item.id)" v-for="(item,index) in activeList" :key="index">
						<img :src="item.image" alt="" class="sort-image" />
						<div class="sort-text">{{item.title}}</div>
					</div> -->
					<div :class="(activeNum == 1 ? 'active':'') + ' sort-item row-center'" @click="active(1)">
						<img src="@/assets/investment/header-1.png" alt="" class="sort-image">
						<div class="sort-text line-one">{{lang.invest_real_time_quotes}}</div>
					</div>
					<div :class="(activeNum == 2 ? 'active':'') + ' sort-item row-center'" @click="active(2)">
						<img src="@/assets/investment/header-2.png" alt="" class="sort-image">
						<div class="sort-text line-one">{{lang.invest_company_notice}}</div>
					</div>
					<div :class="(activeNum == 3 ? 'active':'') + ' sort-item row-center'" @click="active(3)">
						<img src="@/assets/investment/header-3.png" alt="" class="sort-image">
						<div class="sort-text line-one">{{lang.invest_service_station}}</div>
					</div>
					<div :class="(activeNum == 4 ? 'active':'') + ' sort-item row-center'" @click="active(4)">
						<img src="@/assets/investment/header-4.png" alt="" class="sort-image">
						<div class="sort-text line-one">{{lang.invest_bond_window}}</div>
					</div>
				</div>
				
			</div>
        </div>
        <div class="content">
			<div v-if="activeNum == 1" class="quotes">
				<img src="https://image.sinajs.cn/newchart/min/n/sh603536.gif"/>
			</div>
			<service v-if="activeNum == 3"></service>
			<security v-if="activeNum == 4"></security>
        </div>
	</div>
</template>

<script>
import service from "@/components/investment/service.vue"
import security from "@/components/investment/security.vue"
import {bannerType} from "@/utils/type.js"
import {mapState} from 'vuex'
import { 
	getAdList,
} from '@/api/store.js'
	export default {
		name: "external",
		computed: {
			...mapState({
			  lang: state => state.user.lang,
			})
		},
		data() {
			return {
                currentPage3:1,
				type: this.$route.params.type,
				bannerId: bannerType.investorConcern,
				img_list:[{img:''}],
                activeNum: Number(this.$route.params.type),
				activeList:[{
					id:1,
					image:'../../assets/investment/header-1.png',
					title:'',
					openurl:''
				},{
					id:2,
					image:'../../assets/investment/header-2.png',
					title:'',
					openurl:'https://www.sse.com.cn/assortment/stock/list/info/announcement/index.shtml?productId=603536'
				},{
					id:3,
					image:'../../assets/investment/header-3.png',
					title:'',
					openurl:'/investment/external/3'
				},{
					id:4,
					image:'../../assets/investment/header-4.png',
					title:'',
					openurl:'/investment/external/4'
				}],
			}
		},
		components:{
			service,
			security,
		},
		created() {
			this.getAdListFun()
		},
        beforeRouteUpdate(to, from, next) {
			this.activeNum = Number(to.params.type)
            next();
        },
		beforeRouteLeave (to, from, next) { 
			console.log('beforeRouteLeave',to.params.type)
			// window.clearInterval(this.timer) //清楚定时器   
			next()
		},

		methods:{
			getAdListFun(){
				// 获取广告位图片
				let data = {
					cate_id:this.bannerId
				}
				getAdList(data).then(res=>{
					if(res.data.img_list){
						this.img_list = res.data.img_list
					}
				})
			},
			active(num){
				if(num == 2){
					window.open('https://www.sse.com.cn/assortment/stock/list/info/announcement/index.shtml?productId=603536', '_blank');
					return
				}else{
					this.path('/investment/external/' + num)
					this.activeNum = num
				}
			},
			handleSizeChange(e){
				 console.log(e,'handleSizeChange')
			},
			handleCurrentChange(e){
				 console.log(e,'handleCurrentChange')
			},
			handleSizeChange(e){
				 console.log(e,'handleSizeChange')
			},
			handleSizeChange(e){
				 console.log(e,'handleSizeChange')
			},
			path(routePath){
				 this.$router.push(routePath); 
			}
		}


	}
</script>

<style lang="less" scoped>
/* 响应式布局 */
@media (max-width: 768px) {
  .investment{
    
    .header-investment{
		.investmentHeader{
    		width:100% !important;
		}
	}
	.content{
		width:100% !important;
		.quotes{
			img{
				width: 70% !important;
			}
		}
	}
  }
}
/* 响应式布局 */
@media (max-width: 430px) {
  .investment{
    
    .header-investment{
		.investmentHeader{
    		width:100% !important;
			.sort{
				.sort-item{
					.sort-image{
						width: 1.5rem !important;
						margin-right: 0.75rem !important;
					}
					.sort-text{
						font-size: 0.5rem !important;
					}
				}
			}
		}
	}
	.content{
		width:100% !important;
		.quotes{
			img{
				width: 70% !important;
			}
		}
	}
  }
}
.investment{
    background-color: #f2f2f2;
    
    .header-investment{
        width: 100%;
        height: 6.25rem;
        background: #EAEAEA;
		.investmentHeader{
			width: 75rem;
			height: 6.25rem;
			margin: 0 auto;
			.sort{
				width: 100%;
				height: 100%;
				justify-content: space-between;
				.sort-item{
					cursor: pointer;
					width: 25%;
					height: 100%;
					padding: 0 1rem;
					.sort-image{
						width: 2.63rem;
						margin-right: 1rem;
					}
					.sort-text{
						font-weight: bold;
						font-size: 1rem;
						color: #333333;
					}
				}
				.sort-item:hover{
					background-color: #f2f2f2;
				}
				.active{
					background-color: #fff !important;
				}
				
			}
			.sort-line{
				margin-top: 0.5rem;
				width: 100%;
				height: 0.06rem;
				background: #E1E1E1;
			}
			// @keyframes slide-left {
			// from {
			// 	transform: translateX(0);
			// }
			// to {
			// 	transform: translateX(5rem); /* 向右移动100px */
			// }
			// }
			.select-line{
				background-color: #D70C19;
				width: 18.75rem;
				height: 0.2rem;    
				top: -0.2rem;
				position: relative;
				left: 0rem;
				transition: all 0.2s ease; /*添加过渡效果，使平移变得更平滑*/
			}
		}
    }
    .content{
        width: 75rem;
        margin: 0 auto;
		.quotes{
			padding: 6rem 0;
			text-align: center;
			img{
				width: 49.5rem;
			}
		}
    }
}
</style>