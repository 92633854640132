<template>
	<div class="service">
		<div class="enterprise">
			<div class="title">{{ liaison_station.company_name }}</div>
			<div class="line">
				<div class="line-item column-center">
					<img src="@/assets/investment/enterprise-1.png" alt="" class="line-image">
					<div class="line-one line-text">{{ liaison_station.address }}</div>
				</div>
				<div class="line-item column-center">
					<img src="@/assets/investment/enterprise-2.png" alt="" class="line-image">
					<div class="line-one line-text">{{ liaison_station.board_phone }}</div>
				</div>
				<div class="line-item column-center">
					<img src="@/assets/investment/enterprise-3.png" alt="" class="line-image">
					<div class="line-one line-text">{{ liaison_station.phone }}</div>
				</div>
				<div class="line-item column-center">
					<img src="@/assets/investment/enterprise-4.png" alt="" class="line-image">
					<div class="line-one line-text">{{ liaison_station.email }}</div>
				</div>
			</div>
		</div>
		<div class="enterprise">
			<div class="title">{{lang.contact_station}}</div>
			<div class="content">
				<div class="contact-item column-center" style="cursor: pointer;" v-for="(item,index) in img_list" :key="index" @click="openUrl(item.url)">
					<img :src="item.img" alt="" class="contact-image">
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { 
	getLiaisonStation,
	getAdList
} from '@/api/store.js'
import searchLine from "@/components/searchLine.vue"
import {bannerType} from "@/utils/type.js"
import {mapState} from 'vuex'
	export default {
		name: "service",
		computed: {
			...mapState({
			  lang: state => state.user.lang,
			})
		},
		data() {
			return {
				type: this.$route.params.type,
				bannerId: bannerType.investor,
                currentPage3:1,
				img_list:[],
				liaison_station:{},
			}
		},
		components:{
			searchLine,
		},
		created() {
			this.getLiaisonStationFun()
			this.getAdListFun()
			
		},
        beforeRouteUpdate(to, from, next) {
            this.type = to.params.type
            next();
        },
		mounted() {
		},
		methods:{
			
			openUrl(url){
				if(url){
					window.open(url, '_blank');
				}
			},
			getAdListFun(){
				// 获取广告位图片
				let data = {
					cate_id:this.bannerId
				}
				getAdList(data).then(res=>{
					this.img_list = res.data.img_list
				})
			},
			getLiaisonStationFun(){
				// 获取公司信息
				getLiaisonStation().then(res=>{
					this.liaison_station = res.data.liaison_station
				})
			},
			handleSizeChange(e){
				 console.log(e,'handleSizeChange')
			},
			handleCurrentChange(e){
				 console.log(e,'handleCurrentChange')
			},
			handleSizeChange(e){
				 console.log(e,'handleSizeChange')
			},
			handleSizeChange(e){
				 console.log(e,'handleSizeChange')
			},
			path(routePath){
				 this.$router.push(routePath); 
			}
		}


	}
</script>

<style lang="less" scoped>
/* 响应式布局 */
@media (max-width: 768px) {
  .service .enterprise .line .line-item{
    width: 25% !important;
	.line-text{
		width: 100% !important;
	}
  }
}
@media (max-width: 430px) {
  .service .enterprise .line .line-item{
	.line-text{
		font-size: 0.5rem !important;
	}
	.line-image{
		width: 1.5rem;
		height: 1.5rem;
	}
  }
  .service .enterprise .title{
	font-size: 1rem !important;
  }
  .service .enterprise .line{
	height: 8rem !important;
	 .line-item .line-image{
		width: 1.75rem !important;
		height: 1.75rem !important;
	}

  }
}
.service{
	width: 100%;
	padding-bottom: 4.3rem;
	text-align: center;
	.enterprise{
		width: 100%;
		.title{
			font-weight: bold;
			font-size: 1.5rem;
			color: #333333;
			padding: 3rem 0;
			margin-top: 1.3rem;
		}
		.line{
			width: 100%;
			height: 9.81rem;
			display: flex;
			justify-content: space-between;
			.line-item{
				width: 17.63rem;
				height: 100%;
				cursor: pointer;
				background-color: #fff;
				padding: 2.44rem 1rem 1.88rem;
    			justify-content: space-between;
				.line-text{
					width: 15.63rem;
					font-weight: bold;
					font-size: 0.88rem;
					color: #333333;
				}
				.line-image{
					width: 3.25rem;
					height: 3.25rem;
				}
			}
		}
		.content{
			width: 100%;
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			.contact-item{
				width: 36.69rem;
				height: 14.63rem;
				border-radius: 0.5rem;
				padding: 2.88rem 1rem;
				background-color: #fff;
				margin: 0 auto 1.5rem;
				.contact-image{
					width: 100%;
				}
			}
			.contact:hover{
					box-shadow: 0 0.125rem 0.75rem 0 rgba(0,0,0,0.4);
			}
		}
	}
}
</style>