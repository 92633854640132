<template>
	<div>
		<header>
			<!-- 头部内容 -->
			<div class="header-menu"><!-- url.indexOf("http") != -1 -->
				<div class="container-secondary-main pc-header hidden-md-and-down">
					<el-menu   class="el-menu-demo" mode="horizontal" :default-active="active"
						background-color="rgba(51, 51, 51, 0)" text-color="#fff" active-text-color="#fff">
						<el-menu-item index="0" ><img @click="path('/index')" :src="site_info.logo" style="width: 12rem;"/></el-menu-item>
						<el-menu-item :index="lang.index" :class="active==lang.index?'is-active':''"><span @click="path('/index')">{{lang.index}}</span></el-menu-item>
						<el-submenu v-for="menu in nav" :index="menu.title" :class="active==menu.title?'is-active':''" v-if="menu.child_nav && menu.child_nav.length>0">
						  <template slot="title" >{{menu.title}}</template>
						  <el-menu-item v-for="childnav in menu.child_nav" :index="childnav.title">
							  <a v-if="childnav.url.startsWith('http')" :href="childnav.url" target="_blank">{{childnav.title}}</a>
							  <span v-else @click="path(childnav.url)">{{childnav.title}}</span>
						  </el-menu-item>
						</el-submenu>
						<el-menu-item v-for="menu in nav" v-if="!menu.child_nav" :index="menu.title" :class="active==menu.title?'is-active':''">
							<a v-if="menu.url.startsWith('http')" :href="menu.url" target="_blank">{{menu.title}}</a>
							<span v-else-if="isFirstCharSlash(menu.url)" @click="path(menu.url)">{{menu.title}}</span>
							<span v-else >{{menu.title}}</span>
						</el-menu-item>	
						<el-submenu :index="lang.common_choose_language" >
							<template slot="title" >{{lang.common_choose_language}}</template>
							<el-menu-item :index="index.toString()" v-for="(website,index) in web_site">
								<a :href="website.url" >{{website.language}}</a>
							</el-menu-item>
						</el-submenu>
					</el-menu>
				</div>
				<div class="moblie-header hidden-md-and-up">
					<img :src="site_info.logo" />
					<el-button @click="drawer = true" type="primary" class="moblie-header-menu">
					  <i class="el-icon-s-fold"></i>
					</el-button>
				</div>
			</div>
		</header>
		<el-drawer
		class="hidden-md-and-up"
		  :title="site_info.title"
		  size="73%"
		  :visible.sync="drawer"
		  :direction="direction"
		  :before-close="handleClose">
		  <div class="moblie-header-el-menu">
			  <el-menu
			        class="el-menu-vertical-demo"
					:unique-opened="true"
			        @open="handleOpen"
			        @close="handleCloseMenu">
					<el-menu-item index="1" :class="active==lang.index?'is-active':''"><span slot="title" @click="path('/index')">{{lang.index}}</span></el-menu-item>
					<el-submenu v-for="menu in nav" :index="menu.index.toString()" :class="active==menu.title?'is-active':''" v-if="menu.child_nav && menu.child_nav.length>0">
					  <template slot="title" >{{menu.title}}</template>
					  <el-menu-item-group>
						  <el-menu-item v-for="childnav in menu.child_nav" :index="childnav.index.toString()">
						  	<a v-if="childnav.url.startsWith('http')" :href="childnav.url" target="_blank">{{childnav.title}}</a>
						  	<span v-else @click="path(childnav.url)">{{childnav.title}}</span>
						  </el-menu-item>
					  </el-menu-item-group>
					</el-submenu>
					<el-menu-item v-for="menu in nav" v-if="!menu.child_nav" :index="menu.index.toString()" :class="active==menu.title?'is-active':''">
						<a v-if="menu.url.startsWith('http')" :href="menu.url" target="_blank">{{menu.title}}</a>
						<span v-else-if="isFirstCharSlash(menu.url)" @click="path(menu.url)">{{menu.title}}</span>
						<span v-else >{{menu.title}}</span>
					</el-menu-item>
					<el-submenu index="11" >
						<template slot="title" ><span>{{lang.common_choose_language}}</span></template>
						<el-menu-item-group>
							<el-menu-item :index="index.toString()" v-for="(website,index) in web_site">
								<a :href="website.url" >{{website.language}}</a>
							</el-menu-item>
						</el-menu-item-group>
					</el-submenu>
			      </el-menu>
		  </div>
		</el-drawer>
		<main>
			<!-- 主内容区域 -->
			<router-view></router-view>
		</main>
		<footer class="bd-footer py-4  bg-body-tertiary">
			<div class="container container-footer  py-4 py-md-4 px-4 px-md-3 text-body-secondary">
				<div class="row">
					<div class="col-6 col-md-2 mb-3 footer-nav"  v-for="(menu,index) in nav"  v-if="index<4&&menu.child_nav && menu.child_nav.length>0">
						<h5>{{menu.title}}</h5>
						<ul class="list-unstyled" >
							<li v-for="childnav in menu.child_nav">
								<a class="mb-2" v-if="childnav.url.startsWith('http')" :href="childnav.url">{{childnav.title}}</a>
								<a class="mb-2" v-else @click="path(childnav.url)">{{childnav.title}}</a>
							</li>
							
						</ul>
					</div>
					<div class="col-12 col-md-2 mb-3 footer-link" >
						<div class="footer-w">
							<img :src="site_info.wx_ewm" />
							<p>{{lang.common_footer_wechat}}</p>
							<h5 style="color: #B60914;">{{site_info.phone}}</h5>
							<h6>{{site_info.work_time}}</h6>
						</div>
						<div class="footer-l">
							<a href="https://mall.jd.com/index-891721.html" target="_blank"><img src="../assets/jingdong.png" /></a>
							<a href="https://huifa.tmall.com/shop/view_shop.htm?spm=a1z10.3-b-s.w5001-23041550203.2.101b7400GSXWqC&user_number_id=4102405080&scene=taobao_shop" target="_blank"><img src="../assets/tianmao.png" /></a>
							<a href="mailto:admin@huifafoods.com" target="_blank"><img src="../assets/email.png" /></a>
						</div>
					</div>
				</div>
				<div class="footer-z">
					<p>{{lang.common_footer_copyright}}</p>
					<p><a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">{{site_info.icp}}</a>&nbsp;&nbsp;|&nbsp;&nbsp;{{lang.common_footer_lgw}}</p>
				</div>
			</div>
		</footer>
		
	</div>
</template>

<script>
	import '@/style/offcanvas-navbar.css' // 或者 bootstrap.min.css';
	import { getInfo } from "@/api/index";
	import { changeTitle,changeMeta } from '@/utils/index.js'
	import {
		mapState
	} from 'vuex'
	export default {
		computed: {
			...mapState({
			  lang: state => state.user.lang,
			  nav: state => state.user.nav,
			  site_info: state => state.user.site_info,
			  web_site: state => state.user.web_site
			}),
			active() {
			  return this.$route.matched[0].meta.title;
			},
		},
		data() {
			return {
				type: 1,
				drawer: false,
				direction: 'rtl'
			}
		},
		mounted() {
			
		},
		created() {
			changeTitle(this.site_info.title);
			changeMeta({ name: 'keywords', content: this.site_info.seo_keys });
			changeMeta({ name: 'description', content: this.site_info.seo_desc });
			const host = window.location.hostname;
			if (host === 'offweb-eng.ehuifa.com.cn') {
			//if (host === 'localhost') {
			  import('@/style/index-englist.css');
			}
			this.traverseRoutes(this.$router.options.routes);
		},
		methods: {
			traverseRoutes(routes) {
			      routes.forEach(route => {
					if(route.meta.id=='0'){	
						route.meta.title=this.lang.index
					}else{
						this.nav.forEach(item => {
							if(item.ordid==route.meta.id){
								route.meta.title=item.title
							}
						})
					}
			       
			      });
				  
			},
			handleOpen(key, keyPath) {
			        console.log(key, keyPath);
			},
			handleCloseMenu(key, keyPath) {
			        console.log(key, keyPath);
			},
			handleClick() {
				console.log('Element clicked!');
			},
			handleClose(done) {
			        done();
			},
			
			isFirstCharSlash(url) {
			    return url.charAt(0) === '/';
			},
			path(routePath, type) {
				//this.type=type
				this.$router.push(routePath);
				this.drawer=false
			}
			

		}
	}
</script>
<style lang="less">
	.header-menu {
		position: fixed;
		width: 100%;
		background-color: rgba(51, 51, 51, 0.4);
		height: 4rem;
		z-index: 2;

		.el-menu.el-menu--horizontal {
			border-bottom: none;
		}

		.el-menu--horizontal>.el-menu-item,
		.el-submenu,
		.el-menu--horizontal>.el-submenu .el-submenu__title {
			background: none !important;
			height: 4rem;
			line-height: 4rem;
		}

		.el-icon-arrow-down:before {
			content: "";
		}

		.el-menu--horizontal>.el-submenu .el-submenu__title {
			background: none !important;
		}



	}
	

	.el-submenu__title,
	.el-menu-item {
		padding: 0 0.8125rem;
		font-size: 1rem;
		background: none !important;
	}
	

	.el-submenu__title:hover,
	.el-menu-item:hover {
		font-weight: bold;
	}

	.el-menu-item.is-active {
		font-weight: bold;
	}

	.el-menu--horizontal>.el-menu-item.is-active {
		border-bottom: 2px solid #D70C19 !important;
	}

	.el-menu--horizontal>.el-submenu .el-submenu__icon-arrow {
		display: none;
	}

	.el-menu--popup {
		background: rgba(51, 51, 51, 0.4) !important;
		min-width: 7.5rem;
	}
	.el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
	    border-bottom: 2px solid #D70C19 !important;
	}

	.el-menu--popup-bottom-start {
		margin-top: 0px;
	}

	.el-menu--horizontal .el-menu .el-menu-item,
	.el-menu--horizontal .el-menu .el-submenu__title {
		background: none !important;
		border: none !important;
	}

	.el-menu--horizontal .el-menu .el-menu-item:hover,
	.el-menu--horizontal .el-menu .el-submenu__title:hover,.el-menu--popup>.el-menu-item a:hover {
		color: #D70C19 !important;
	}

	.el-menu--horizontal>.el-menu-item a,
	.el-menu--horizontal>.el-menu-item a:hover,.el-menu--horizontal>.el-menu-item span,
	.el-menu--horizontal>.el-menu-item span:hover,.el-menu-item a {
		text-decoration: none;
		display: block;
		color: #fff;
	} 
	.el-menu--horizontal > .el-menu-item:first-child.is-active,
	.el-menu--horizontal > .el-menu-item:nth-child(9).is-active,
	.el-menu--horizontal > .el-menu-item:nth-child(10).is-active,
	.el-menu--horizontal > .el-submenu:last-child.is-active .el-submenu__title{
		border: none !important;
	}
	.el-menu-item a,.el-menu-item span{
		display: block;
		width: 100%;
	}
	
</style>