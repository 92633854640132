//引入axios请求
import axios from 'axios'
//引入element-ui里面的消息提示
import { Message } from 'element-ui'

// export const BASE_API="http://offweb-api.ehuifa.com.cn"
export const BASE_API="https://webapi.huifafoods.com"
// 创建axios实例
const service = axios.create({
  baseURL: BASE_API, // 所有的请求地址前缀部分(没有后端请求不用写)
  timeout: 10 * 60 * 1000, // 请求超时时间,这里设置为10分钟
  withCredentials: true, //允许跨域携带凭证(cookie)
  credentials: true, //后端允许跨域携带cookie
  headers: {
    //请求头设置后端需要的传参类型
    'Content-Type': 'application/json; charset=utf-8'
  }
})

// request拦截器
service.interceptors.request.use(config => {
  // 这里可以在请求头中配置token,根据自己的项目需要自行配置
  return config
}, error => {
  // 错误日志打印
  Promise.reject(error)
})

// response拦截器
/* service.interceptors.response.use(
    response => {
      //对数据返回做什么,可以可以获取到接口的code状态码，根据不同的状态码来处理不同的逻辑
      return response.data
    },
    error => {
      console.log('err' + error)
      Message({
        message: error.message,
        type: 'error',
        duration: 3 * 1000
      })
      return Promise.reject(error)
    }
) */
// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data

    // if the custom code is not 20000, it is judged as an error.
    if (res.code !== 200) {
      Message({
        message: res.msg || 'Error',
        type: 'error',
        duration: 5 * 1000
      })

      return Promise.reject(new Error(res.msg || 'Error'))
    } else {
      return res
    }
  },
  error => {
    console.log('err' + error) // for debug
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)



export default service

