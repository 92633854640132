import Vue from 'vue';
import Router from 'vue-router';
import AppLayout from '../components/AppLayout.vue';
import external from "@/views/investment/external.vue";


Vue.use(Router);

export const constantRoutes = [

	{
		path: '/',
		component: AppLayout,
		redirect: '/index',
		meta: {
		  title: "首页",
		  id:'0'
		},
		children: [{
			path: 'index',
			name: 'Index',
			component: () => import('@/views/index/index'),
			meta: {
				title: 'index',
				icon: 'index'
			}
		}]
	},
	{//走进惠发
		path: '/enteringHuifa',
		component: AppLayout,
		redirect: '/enteringHuifa',
		meta: {
		  title: "走进惠发",
		  id:'1'
		},
		children: [{
			path: 'index/:id',
			name: 'enteringHuifa',
			props: true,
			component: () => import('@/views/enteringHuifa/index'),
			meta: {
				title: 'enteringHuifa',
				icon: 'enteringHuifa'
			}
		},
		{//id:详情id，type:面包屑类型如：企业荣誉页面跳转参数：EnterpriseHonor
			path: 'detail/:id/:type',
			name: 'detail',
			component: () => import('@/views/enteringHuifa/detail'),
			meta: {
				title: 'detail',
				icon: 'detail'
			}
		}]
	},
	{
		path: '/productplatform',
		component: AppLayout,
		redirect: '/productplatform',
		meta: {
			title: "产品展示",
			id:'2'
		},
		children: [{
				path: 'index/:type',
				name: 'productplatform',
				component: () => import('@/views/productplatform/index'),
				meta: {
					title: '产品展示',
					icon: 'productplatform'
				}
			},
			{
				path: "see/:id",
				component: () => import("@/views/productplatform/see"),
				hidden: true,
				name: "productplatform_see",
				meta: {
					title: "产品详情",
					bread: "产品展示,产品详情",
					noCache: true,
					navigation: "/productplatform/see",
					// icon: "menu_big_data"
				}
			},
		]
	},
	{
		path: '/newsCenter',
		component: AppLayout,
		redirect: '/newsCenter',
		meta: {
			title: "惠发动态",
			id:'3'
		},
		children: [{
				path: 'index/:type',
				name: 'newsCenter',
				component: () => import('@/views/newsCenter/index'),
				meta: {
					title: '新闻中心',
					icon: 'newsCenter'
				}
			},
			{
				path: "see/:id",
				component: () => import("@/views/newsCenter/see"),
				hidden: true,
				name: "newsCenter_see",
				meta: {
					title: "新闻中心",
					bread: "新闻中心,新闻详情",
					noCache: true,
					navigation: "/newsCenter/see",
					// icon: "menu_big_data"
				}
			},
		]
	},
	{
		path: '/investment',
		component: AppLayout,
		redirect: "noRedirect",
		meta: {
			title: "投资者关系",
			id:'6'
		},
		children: [{
				path: 'external/:type',
				name: 'investment_external',
				component: external,
				meta: {
					title: '投资者服务联络',
					icon: 'investment'
				},
			},
			{
				path: "see/:id",
				component: () => import("@/views/investment/see"),
				hidden: true,
				name: "investment_see",
				meta: {
					title: "投资者关系",
					bread: "投资者关系,详情",
					noCache: true,
					navigation: "/investment/see",
					// icon: "menu_big_data"
				},
			},
		]
	},
	{
		path: '/projectLayout',
		component: AppLayout,
		redirect: '/projectLayout',
		meta: {
			title: "项目布局",
			id:'4'
		},
		children: [{
				path: 'see/:id',
				name: 'projectLayout',
				component: () => import('@/views/projectLayout/index'),
				meta: {
					title: '项目详情',
					icon: 'projectLayout'
				},
			},
		]
	},
	{
		path: '/humanresources',
		component: AppLayout,
		redirect: '/humanresources',
		meta: {
			title: "人力资源",
			id:'5'
		},
		children: [{
				path: 'index/:id',
				props: true,
				name: 'humanresources',
				component: () => import('@/views/humanresources/index'),
				meta: {
					title: '人力资源',
					icon: 'humanresources'
				}
			},
		]
	},
	{
		path: '/email',
		component: AppLayout,
		redirect: '/email',
		meta: {
			title: "企业邮箱",
		},
		children: [{
				path: 'index',
				props: true,
				name: 'email',
				component: () => import('@/views/email/index'),
				meta: {
					title: '企业邮箱',
					icon: 'email'
				}
			},
		]
	},
	{
		path: '/contactus',
		component: AppLayout,
		redirect: '/contactus',
		meta: {
			title: "联系我们",
			id:'9'
		},
		children: [{
				path: 'index',
				props: true,
				name: 'contactus',
				component: () => import('@/views/contactus/index'),
				meta: {
					title: '联系我们',
					icon: 'contactus'
				}
			},
		]
	},
	
	/* {
		path: '/news',
		component: AppLayout,
		redirect: '/news',
		meta: {
			title: "新闻",
		},
		children: [{
				path: 'detail/:id',
				name: 'detail',
				component: () => import('@/views/news/detail'),
				meta: {
					title: '新闻详情',
					icon: 'detail'
				}
			},
		]
	}, */
	// {
	//   path: '/enteringHuifa',
	//   component: AppLayout,
	//   redirect: '/enteringHuifa/Introduction',
	//   name: 'enteringHuifa',
	//   meta: { title: 'enteringHuifa', icon: 'el-icon-s-help' },
	//   children: [
	//     {
	//       path: 'Introduction',
	//       name: 'Introduction',
	//       component: () => import('@/views/enteringHuifa/Introduction'),
	//       meta: { title: 'Introduction', icon: 'Introduction' }
	//     },
	//     {
	//       path: 'majorEvents',
	//       name: 'majorEvents',
	//       component: () => import('@/views/enteringHuifa/majorEvents'),
	//       meta: { title: 'majorEvents', icon: 'majorEvents' }
	//     }
	//   ]
	// },
	{
		path: '/404',
		component: () => import('@/views/404'),
		hidden: true
	},

	// 404 page must be placed at the end !!!
	{
		path: '*',
		redirect: '/404',
		hidden: true
	}
];

const createRouter = () => new Router({
	// mode: 'history', // require service support\
	mode: 'history',
	scrollBehavior: () => ({
		y: 0
	}),
	routes: constantRoutes
})

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
	const newRouter = createRouter()
	router.matcher = newRouter.matcher // reset router
}
const VueRouterPush = Router.prototype.push
Router.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

export default router;