<template>
	<div class="security">
		<div class="enterprise">
			<div class="title">{{lang.invest_bond_hotspot}}</div>
			
            <div class="search">
                <searchLine :isBtnBorder="true" @update-data="searchFun"></searchLine>
            </div>
			<div class="list">
				<div class="type" @click="path('/investment/see/'+ item.id)" style="cursor: pointer;" v-for="(item,index) in articleList.data" :key="index" v-if="articleList.data.length > 0">
					<!-- <img class="type-image" v-if="item.img" :src="item.img" alt=""/>
					<img class="type-image" v-else src="@/assets/no_img.png" alt=""/> -->
					<div class="type-image" :style="{backgroundImage: `url(${item.img})`}" v-if="item.img"></div>
					<div class="type-image-nodata" :style="{ 'background': 'url('+require('@/assets/no_img.png')+')'}" v-else></div>
					<div class="text bold column-center">
                        <div class="text-p line-one">{{ item.title }}</div>
                        <div class="text-p line-one" style="color: #999;font-weight: 400;">{{ item.add_time}}</div>
                    </div>
				</div>
                <div v-if="articleList.data.length == 0" class="column-center" style="width: 100%;margin: 3rem 0;">
					<img class="type-image" src="@/assets/nodata.gif" alt=""/>
					<div style="margin-top: 2rem;">{{lang.project_no_data}}</div>
				</div>
			</div>
			<div class="pagination" v-if="articleList.data.length > 0">
				<el-pagination
				background
				:layout="layout"
				:pager-count="5"
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
				:current-page.sync="articleList.current_page"
				:page-size="articleList.per_page"
				:total="articleList.total">
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
import searchLine from "@/components/searchLine.vue"
import {mapState} from 'vuex'
import { 
	getArticleList,
} from '@/api/store.js'
	export default {
		name: "security",
		computed: {
			...mapState({
			  lang: state => state.user.lang,
			}),
			layout() {
			  return this.isMobile ? 'prev, pager, next' : 'prev, pager, next, jumper'; // 根据isMobile的值动态设置layout属性值。如果isMobile为true，则不显示跳转输入框。
			}
		},
		data() {
			return {
				type: this.$route.params.type,
                currentPage3:1,
                activeNum:0,
				page:1,
				keywords:'',
				articleList: {
					data:[]
				},
				isMobile: false	  
			}
		},
		components:{
			searchLine,
		},
		created() {
			this.getArticleListFun()
		},
        beforeRouteUpdate(to, from, next) {
            this.type = to.params.type
            next();
        },
		mounted() {
			window.screenWidth = document.body.clientWidth;
			if(window.screenWidth<=768){
				this.isMobile=true
			}else{
				this.isMobile=false
			}
		},
		methods:{
			getArticleListFun(){
				// 获取公司信息
				let data = {
					type_id: 6,
					keywords:this.keywords,
					page: this.page,
				}
				getArticleList(data).then(res=>{
					this.articleList = res.data.article_list
				})
			},
			handleSizeChange(e){
				 console.log(e,'handleSizeChange')
			},
			handleCurrentChange(e){
				this.page = e
                this.getArticleListFun()
			},
			searchFun(e){
				 console.log(e,'e')
				 this.keywords = e
                 this.page = 1
                this.getArticleListFun()
			},
			path(routePath){
				 this.$router.push(routePath); 
			}
		}


	}
</script>

<style lang="less" scoped>

@media (max-width: 768px) {
  .security .enterprise .search{
    margin: 0 1rem;
  }
  .security .enterprise .list{
    padding: 0 1rem;
    justify-content: space-between;
	.type{
		width: 48% !important;
		margin-right: 0 !important;
		height: 17.94rem !important;
		.type-image{
			width: 100% !important;
			height: 12rem !important;
		}
		.type-image-nodata{
			width: 100% !important;
			height: 12rem !important;
		}
	}
  }
}
::v-deep.el-button.is-round {
    border-radius: 2rem;
    padding: 0.5rem 1.25rem;
}
.security{
	width: 100%;
	padding-bottom: 4.3rem;
	text-align: center;
	.enterprise{
		width: 100%;
		.title{
			font-weight: bold;
			font-size: 1.5rem;
			color: #333333;
    		margin-top: 3.3rem;
		}
		
		.list{
                width: 100%;
                margin-top: 1rem;
                display: flex;
                flex-wrap: wrap;
                .type{
                    width: 24rem;
					cursor: pointer;
					height: 22.94rem;
					margin-right: 1.5rem;
					margin-bottom: 1.5rem;
					border-radius: 0.5rem;
					background-color: #fff;
					.type-image{
						width: 24rem;
						height: 17.19rem;
						background-size: 100% auto !important;
						background-position: center !important;
						background-repeat: no-repeat !important;
					}
                    .type-image-nodata{
                        width: 24rem;
                        height: 17.19rem;
                        background-size: 50% auto !important;
                        background-position: center !important;
                        background-repeat: no-repeat !important;
                    }
                    .text{
                        width: 100%;
                        height: 5.75rem;
                        border-radius: 0.5rem;
                        padding: 0 1rem;
                        .text-p{
                            text-align: left;
                            width: 100%;
                            margin: 0.5rem 0;
                        }
                    }
                }
                .type:nth-child(3n){margin-right:0}
                .type:hover{
                     box-shadow: 0 0.125rem 0.75rem 0 rgba(0,0,0,0.4);
                }
                .type:hover{
                     box-shadow: 0 0.125rem 0.75rem 0 rgba(0,0,0,0.4);
                }
            }
            .pagination{
                width: 100%;
                flex-direction:row-reverse
            }
	}
}
</style>