import request from '@/utils/request'

//示例以application/json格式传参
export function login(data) {
  return request({
    url: '/admin/login',
    method: 'post',
    data: data
  })
}

// 获取首页banner
export function getIndexBanner (data) {
  return request({
      url: '/get_index_banner',
      method: 'get',
      params: data
  })
}
// 获取首页产品分类
export function getIndexProductCate (data) {
  return request({
      url: '/get_index_product',
      method: 'get',
      params: data
  })
}
// 获取首页项目布局
export function getIndexProject (data) {
  return request({
      url: '/get_index_project',
      method: 'get',
      params: data
  })
}

// 获取首页新闻动态
export function getIndexNews (data) {
  return request({
      url: '/get_index_news',
      method: 'get',
      params: data
  })
}
// 获取首页新闻banner
export function getIndexNewsBanner (data) {
  return request({
      url: '/get_index_news_banner',
      method: 'get',
      params: data
  })
}
// 获取产品分类
export function getProductCate (data) {
  return request({
      url: '/get_product_cate',
      method: 'get',
      params: data
  })
}
// 获取产品列表
export function getProductList (data) {
  return request({
      url: '/get_product_list',
      method: 'get',
      params: data
  })
}
// 获取产品信息
export function getProductInfo (data) {
  return request({
      url: '/get_product_info',
      method: 'get',
      params: data
  })
}
// 获取广告位图片信息
export function getAdList (data) {
  return request({
      url: '/get_ad_list',
      method: 'get',
      params: data
  })
}

//惠发动态
//获取文章分类
export function getArticleCateFormType (data) {
  return request({
      url: '/get_article_cate_form_type',
      method: 'get',
      params: data
  })
}
//惠发动态
////获取获取惠发动态所有分类文章列表
export function getArticleList (data) {
  return request({
      url: '/get_article_list',
      method: 'get',
      params: data
  })
}
//惠发动态
////获取获取惠发动态详情
export function getArticleInfo (data) {
  return request({
      url: '/get_article_info',
      method: 'get',
      params: data
  })
}

//惠发动态
////获取获取惠发动态详情
export function getArticleTopList (data) {
  return request({
      url: '/get_article_top_list',
      method: 'get',
      params: data
  })
}
//投资者服务联络站
export function getLiaisonStation (data) {
  return request({
      url: '/get_liaison_station',
      method: 'get',
      params: data
  })
}
