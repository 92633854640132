export function changeMeta(newMeta) {
	const metaElement = document.querySelector(`meta[name='${newMeta.name}']`) || document.createElement('meta');
	metaElement.setAttribute('name', newMeta.name);
	metaElement.setAttribute('content', newMeta.content);
	if (!document.querySelector(`meta[name='${newMeta.name}']`)) {
		document.head.appendChild(metaElement);
	} else {
		metaElement.setAttribute('content', newMeta.content);
	}
}
// 在Vue组件的methods中
export function changeTitle(newTitle) {
	document.title = newTitle;
}
//去除html标签
export function stripHTMLTags(html) {
	const doc = new DOMParser().parseFromString(html, 'text/html');
	return doc.body.textContent || "";		  
}