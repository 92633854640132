import router from './router'
import store from './store'
import { Message } from 'element-ui'



router.beforeEach(async(to, from, next) => {
  // start progress bar

  // set page title
  //document.title = getPageTitle(to.meta.title)
  await store.dispatch('user/getInfo')
  next()
  // determine whether the user has logged in
  
})

router.afterEach(() => {
  // finish progress bar
})
