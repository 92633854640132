
import store from '@/store'
export default {
    el: {
      pagination: {
        goto: store.getters.lang.goto,
        pagesize: '条/页',
        total: `{total} 条`,
        pageClassifier: store.getters.lang.page
      }
    }
  };
  