import request from '@/utils/request'

//示例以application/json格式传参
export function login(data) {
  return request({
    url: '/admin/login',
    method: 'post',
    data: data
  })
}

//示例在url后面拼接参数传参
export function test(params) {
  return request({
    url: '/admin/login',
    method: 'post',
    params: params
  })
}
//测试
export function getceshi() {
  return request({
    url: "/index",
    method: "get"
  });
}
//导航栏 公共信息
export function getInfo() {
  return request({
    url: "/get_site_config",
    method: "get"
  });
}
//惠发简介
export function getHuifaIntroduce() {
  return request({
    url: "/get_huifa_introduce",
    method: "get"
  });
}
//惠发大事记
export function getHuifaMemorabilia() {
  return request({
    url: "/get_huifa_memorabilia",
    method: "get"
  });
}
//惠发企业文化
export function getHuifaCulture() {
  return request({
    url: "/get_huifa_culture",
    method: "get"
  });
}
//惠发企业荣誉
export function getHuifaHonor(params) {
  return request({
    url: "/get_huifa_honor",
    method: "get",
	params
  });
}
//惠发企业荣誉详情
export function getHuifaHonorInfo(params) {
  return request({
    url: "/get_huifa_honor_info",
    method: "get",
	params
  });
}
//新闻
export function getArticleList(params) {
  return request({
    url: "/get_article_list",
    method: "get",
	params
  });
}
//新闻详情
export function getArticleInfo(params) {
  return request({
    url: "/get_article_info",
    method: "get",
	params
  });
}
//人力资源
export function getHireList(params) {
  return request({
    url: "/get_hire_list",
    method: "get",
	params
  });
}
//招聘详情
export function getHireInfo(params) {
  return request({
    url: "/get_hire_info",
    method: "get",
	params
  });
}

//在线应聘
export function applyJob(data) {
  return request({
    url: "/apply_job",
    method: "post",
    data
  });
}
//联系我们
export function getContactUs(params) {
  return request({
    url: "/get_contact_us",
    method: "get",
	params
  });
}



