import Vue from 'vue'
import Vuex from 'vuex';
Vue.use(Vuex);
import App from './App.vue'
import axios from 'axios'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import ElementUI from 'element-ui';//导入element-ui
import 'element-ui/lib/theme-chalk/index.css';//引入全部组件
import zhLang from 'element-ui/lib/locale/lang/zh-CN'; // 引入官方的中文国际化
import locale from './assets/locale/cn'; // 引入自己的
import './style/index.css';
import './element-variables.scss'
Vue.use(ElementUI,{
  locale: { ...zhLang, ...locale }, // 使用本地的 locale 去覆盖官方的 zhLang
});
Vue.use(ElementUI, { size: 'small', zIndex: 3000 });//设置全局配置对象
import 'bootstrap/dist/css/bootstrap.css'// 或者 bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js'// 或者 bootstrap.min.css';
import router from './router'; // 引入路由配置文件
import Swiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
Vue.use(Swiper)
import store from './store'
import '@/permission' // permission control
 
Vue.use(BootstrapVue) // 安装BootstrapVue插件
Vue.use(IconsPlugin) // 安装图标插件（如果需要的话）

Vue.config.productionTip = false
Vue.prototype.$http = axios
new Vue({
  router, // 使用路由
  store,
  render: h => h(App),
}).$mount('#app')
