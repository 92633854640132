import { getInfo } from "@/api/index";

const getDefaultState = () => {
  return {
    navbarid: '',
	lang:{
    goto:'',
    page:''
  },
	nav:[],
	site_info:[],
	web_site:[],
	product_cate_list:[]//产品分类
  }
}

const state = getDefaultState()

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState())
  },
  SET_NAVBARID: (state, navbarid) => {
    state.navbarid = navbarid
  },
  SET_LANG: (state, lang) => {
    state.lang = lang;
  },
  SET_NAV: (state, nav) => {
    state.nav = nav;
  },
  SET_SITE_INFO: (state, site_info) => {
    state.site_info = site_info;
  },
  SET_WEB_SITE: (state, web_site) => {
    state.web_site = web_site;
  },
  SET_PRODUCT_CATE_LIST: (state, product_cate_list) => {
    state.product_cate_list = product_cate_list;
  }
}
const actions = {
  // user info
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      getInfo().then(response => {
		  
			commit("SET_LANG", response.data.lang);
			commit("SET_NAV", response.data.nav);
			commit("SET_SITE_INFO", response.data.site_info);
			response.data.web_site=response.data.web_site.map((item,index) => ({
				          ...item,
				          index: '11-'+(index+1) ,
			}));
			commit("SET_WEB_SITE", response.data.web_site);
			commit("SET_PRODUCT_CATE_LIST", response.data.product_cate_list);
			
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
}


export default {
  namespaced: true,
  state,
  mutations,
  actions
}

