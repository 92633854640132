import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import getters from './getters'
import user from './modules/user'

Vue.use(Vuex)

const store = new Vuex.Store({
  plugins: [
      new VuexPersistence({
          reducer: state => ({
              user: state.user
          }),
          storage: window.localStorage
      }).plugin
  ],
  modules: {
    user
  },
  getters
})
export default store
