<template>
	<div class="searchLine ">
		<div class="sort ">
			<!-- <div class="sort-left">
				
				<div :class="(activeNum == 1 ? 'active':'') + ' sort-p row-center'" @click="active(1)">推荐</div>
				<div :class="(activeNum == 2 ? 'active':'') + ' sort-p row-center'" @click="active(2)">热门</div>
				<div :class="(activeNum == 3 ? 'active':'') + ' sort-p row-center'" @click="active(3)">最新</div>
			</div> -->
			<div class="search-PublicCharity ">
				<el-input :placeholder="lang.huifa_welfare_search" :class="isBtnBorder? 'input-border' : ''" @change="search"
					v-model="text">
					<i slot="suffix" @click="search">
						<img 
							class="search-icon"
							style="cursor: pointer;"
							src="@/assets/enteringHuifa/search_red.png"
							alt="" />
					</i>
					
				</el-input>
			</div>
		</div>
		<!-- <div class="sort-line"></div>
		<div class="select-line" ref="selectLine"></div> -->
		
	</div>
</template>

<script>
import {
	mapState
} from 'vuex'
export default {
    data() {
        return {
			currentPage3:1,
			text:'',
			activeNum:1,
				  
        }
    },
	computed: {
		...mapState({
			lang: state => state.user.lang,
		})
	},
	props: {
		isBtnBorder: {
			type: Boolean,
			default: false
		},
	},
	methods:{
		/* active(num){
			this.activeNum = num
			this.$refs.selectLine.style.left = ((num - 1) *5 ) + 'rem'
		}, */
		search(){
			this.$emit('update-data', this.text);
		}
		
	}
    
}
</script>

<style lang='less'>
	.searchLine{
		.sort{
			width: 100%;
			height: 3.125rem;
			justify-content: space-between;
			.sort-left{
				display: flex;
				.sort-p{
					width: 5rem;
					height: 3rem;
					text-align: center;
					font-size: 0.875rem;
					color: #999999;
				}
				.sort-p:hover{
					color: rgba(215,12,25,0.3);
					font-weight: bold;
				}
				.active{
					color: #D70C19;
					font-weight: bold;
				}
			}
			.search-PublicCharity{
				width: 15.875rem;
				height: 2.125rem;
				float: right;
				.el-input__inner{
					background: #EEEFF0;
					border-radius: 2.5rem;
					border: 1px solid #CCCCCC;
					
				}
				.el-input__suffix {
				    right: 1rem;
				    top: 0.5rem;
				}
			}
			
		}
		.sort-line{
			margin-top: 0.5rem;
			width: 100%;
			height: 0.06rem;
			background: #E1E1E1;
		}
		// @keyframes slide-left {
		// from {
		// 	transform: translateX(0);
		// }
		// to {
		// 	transform: translateX(5rem); /* 向右移动100px */
		// }
		// }
		.select-line{
			background-color: #D70C19;
			width: 5rem;
			height: 0.2rem;
			position: relative;
			top: -0.15rem;
			left: 0rem;
			transition: all 0.2s ease; /*添加过渡效果，使平移变得更平滑*/
		}
	}
</style>